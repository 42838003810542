/**
 * Direttiva che costruisce la card con la sezione in modifica di un progetto
 */

import { SenecaResponse, ItemFrontEndWrapper, Survey, Item } from "atfcore-commonclasses";

export interface ISectionProjectAdminDirectiveScope extends ng.IScope {
	openSection: Function;
	chartOptions: any;
	card: any;
	windowWidth: number;
	isWidthExceeded: boolean;
	itemType: any;
	hourValue: any;
	isItemOpen: boolean;
	isLearningPlan: boolean;
	isTechSkill: boolean;
	isSoftSkill: boolean;
	percentageItem: any;
	suggestedPerson: any;
	suggestedFromAdmin: any;
	hasSuggested: any;
	globalApplicationData: any;
	libraryApplicationData: any;
	item: any;
	forceToggleIndicator: boolean;
	defaultOnSuggestion: boolean;
	iconToShow: string;
	iconToShowAlt: string;
	isItemAlreadyCertified: boolean;
	isEditing: string;
	totalViewedObjInSection: number;
	totalCertifiedObjInSection: number;
	totalWithAnyActionObjInSection: number;
	techCompetences: any;
	softCompetences: any;
	page: string;
	sectionObj: any;
	getItemPromise: any;
	getItemBlockUI: any;
	numberOfChilds: number;
	isSurveyStarted: boolean;
	isSurveyCertificable: boolean;
	totalDisabledObjInSection: number;
	isMandatory: boolean;
	searchMandatoryIn: Array<any>;
	mandatory: number;
	changeMandatory: Function;
	propedeuticReferenceId: string;
	searchPropedeuticReferenceIdIn: Array<any>;
	isPropedeutic: boolean;
	hasPrerequisites: boolean;
	changePrerequisites: Function;
	propedeuticReferenceTitle: Function;
	propedeuticTitle: string;
	setPropedeuticOffCb: Function;
	prerequisites: any;
	propedeuticReferenceIds: Array<string>;
	checkPropedeuticReferenceIds: Function;
	itemChildId: string;
	isItemDisabled: any;
	fullItem: any;
	adminMode: any;
	currentProject: any;
	isNotDisabled: boolean;
	sectionList: Array<any>;
}
angular.module('app').directive("sectionProjectAdmin", ($window, $state, LibraryApplicationData, GlobalApplicationData, $rootScope, $translate, toaster, blockUI, SurveyService, ProjectService, $stateParams, $sessionStorage) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			item: '=', // Oggetto contenente tutte le informazioni della riga che sto andando a costruire
			fullItem: '=', // Oggetto intero, contiene non solo il childObject ma anche tutte le sue info (propedeuticReferenceId ecc)
			selectedItemList: "=", // bind sull'array che conterrà le righe selezionate
			forceToggleIndicator: '=', // Toggle indicatore di default (open, consumed, percentage...) / suggeritore
			page: '@',
			isLearningPlanEditingMode: '@',
			openItemDetail: '&',
			suggestedPersonExists: '&',
			toggleSuggestedPerson: '&',
			itemChildsExists: '&',
			toggleItemChilds: '&',
			isEditing: '@',
			totalSections: '@',
			currentSectionIndex: '@',
			mandatory: '=?', // obbligatorietà
			searchMandatoryIn: '=', // se sono nel dettaglio del progetto, devo trovare l'obbligatorierà a mano
			propedeuticReferenceId: '=?', // id dell'oggetto propedeutico per quello attuale
			searchPropedeuticReferenceIdIn: '=', // se sono nel dettaglio del progetto, devo trovare la propedeuticità a mano
			propedeuticReferenceTitle: '&', // Titolo della referenza propedeutica
			managePrerequisites: '=',
			propedeuticReferenceIds: '=', // lista dei propedeuticReferenceIds di ogni itemChilds 
			showPropedeuticalItemToThis: '&', // apre una modale per indicare quale oggetto è propedeutico per sbloccare quello corrente
			currentProject: '=',
			sectionList: '='
		},
		link: link,
		templateUrl: 'app/shared/sectionProjectAdmin/sectionProjectAdmin.html'
	};
	function link($scope: ISectionProjectAdminDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Di base la sezione è abilitata
		$scope.isItemDisabled = false;

		// Verifica se sono nella parte amministrativa della Library
		$scope.adminMode = $sessionStorage.adminMode;

		// Salvo l'obbligatorietà
		$scope.isMandatory = !!$scope.mandatory;
		// o, qualora fossi nel dettaglio del progetto, la recupero
		if ($scope.searchMandatoryIn) {
			for (let i = 0; i < $scope.searchMandatoryIn.length; i++) {
				if ($scope.searchMandatoryIn[i].referenceId == $scope.fullItem.itemId) {
					$scope.isMandatory = !!$scope.searchMandatoryIn[i].mandatory;
					break;
				}
			}
		}

		// Se necessario ricerco la propedeuticità
		if ($scope.searchPropedeuticReferenceIdIn) {
			for (let i = 0; i < $scope.searchPropedeuticReferenceIdIn.length; i++) {
				if ($scope.searchPropedeuticReferenceIdIn[i].referenceId == $scope.fullItem.itemId && $scope.searchPropedeuticReferenceIdIn[i].propedeuticReferenceId) {
					$scope.propedeuticReferenceId = $scope.searchPropedeuticReferenceIdIn[i].propedeuticReferenceId;
					break;
				}
			}
		}

		// Verifico se questo oggetto è propedeutico per qualche altro oggetto
		$scope.isPropedeutic = false;
		$scope.checkPropedeuticReferenceIds = () => {
			for (let m = 0; m < $scope.propedeuticReferenceIds.length; m++) {
				if ($scope.propedeuticReferenceIds[m] == $scope.fullItem.referenceId || $scope.propedeuticReferenceIds[m] == $scope.fullItem.itemId) {
					$scope.isPropedeutic = true;
					break;
				} else {
					$scope.isPropedeutic = false;
				}
			}
		}
		if ($scope.propedeuticReferenceIds) {
			$scope.checkPropedeuticReferenceIds();
		}

		// Verifico se esiste un altro oggetto propedeutico per quello attuale
		$scope.prerequisites = {
			hasPrerequisites: $scope.propedeuticReferenceId ? true : false
		}

		// Callback che, se ho chiuso la modale prima di selezionare un oggetto a cui aggiugnere la propedeuiticità, spenge lo switch
		$scope.setPropedeuticOffCb = () => {
			$scope.prerequisites.hasPrerequisites = false;
		}

		// Se ho un item propedeutico a quello attuale, recupero il suo titolo
		if ($scope.prerequisites.hasPrerequisites) {
			$scope.propedeuticTitle = $scope.propedeuticReferenceTitle($scope.fullItem);
		}

		// Quando cambia l'oggetto propedeutico di quello attuale, devo recuperare il suo titolo
		$scope.$watch('propedeuticReferenceId', (newValue, oldValue) => {
			if (newValue) {
				$scope.propedeuticTitle = $scope.propedeuticReferenceTitle($scope.fullItem);
			}
		}, true);

		// Quando cambia la lista degli oggetti propedeutici, devo aggiornare le etichette di obbligatorietà poichè l'item attuale potrebbe essere diventato
		// un item propedeutico a qualcos'altro
		$scope.$watch('propedeuticReferenceIds', (newValue, oldValue) => {
			if (newValue) {
				$scope.checkPropedeuticReferenceIds();
			}
		}, true);

		// Il mandatory a db è un numero (0 / 1), ma all'md-switch serve un boolean. Così devo gestire manualmente lo switch
		$scope.changeMandatory = () => {
			if ($scope.isMandatory == true) {
				$scope.mandatory = 0;
				$scope.isMandatory = false;
			} else {
				$scope.mandatory = 1;
				$scope.isMandatory = true;
			}
		}

		// Configurazione della percentuale
		$scope.chartOptions = {
			animate: {
				duration: 800,
				enabled: true
			},
			barColor: '#1abc9c',
			scaleColor: '',
			trackColor: '#fff',
			lineWidth: 2,
			lineCap: 'butt',
			size: 35
		}

		// Totale oggetti visti della sezione del progetto
		$scope.totalViewedObjInSection = 0;

		// Totale oggetti certificati della sezione del progetto
		$scope.totalCertifiedObjInSection = 0;

		// Totale oggetti con il pallino vuoto (nessuna azione è stata fatta) della sezione del progetto
		$scope.totalWithAnyActionObjInSection = 0;

		// Totale oggetti con il pallino bloccato
		$scope.totalDisabledObjInSection = 0;

		// Elenco di tag delle competenze tecniche
		$scope.techCompetences = [];

		// Elenco di tag delle competenze soft
		$scope.softCompetences = [];

		// Tipo dell'oggetto (video, audio, e-book...)
		$scope.itemType = null;

		// Valore formativo
		$scope.hourValue = null;

		// Oggetto aperto
		$scope.isItemOpen = false;

		// Verifica se è una competenza tecnica
		$scope.isTechSkill = false;

		// Verifica se è una competenza soft
		$scope.isSoftSkill = false;

		// Oggetto iniziato
		$scope.percentageItem = 0;

		// Persona che ha suggerito l'elemento
		$scope.suggestedPerson = null;

		// Suggerito dall'amminsitrazione
		$scope.suggestedFromAdmin = false;

		// Verifica se è un learning Plan
		$scope.isLearningPlan = false;

		// Verifica se ha suggeritori
		$scope.hasSuggested = false;

		// Collegamento all'oggetto globale
		$scope.globalApplicationData = GlobalApplicationData;


		// Verifica se come prima cosa devo mostrare la persona (non ho altri dati disponibili)
		$scope.defaultOnSuggestion = true;

		// Collegamento all'oggetto principale
		$scope.libraryApplicationData = LibraryApplicationData;

		// Oggetto che rappresenta la sezione
		$scope.sectionObj = null;

		// Block ui per il recupero del dettaglio dell'oggetto
		$scope.getItemBlockUI = blockUI.instances.get('getItemBlockUI');

		// Se sono nella pagina di dettaglio del progetto, devo caricare gli oggetti associati alla sezione
		if ($scope.page == 'projectDetail') {
			$scope.numberOfChilds = $scope.item.itemChilds.length;

			// Qualora gli Item di questi referenceId fossero già stati completati, li rimuovo
			if ($scope.propedeuticReferenceIds && $scope.propedeuticReferenceIds.length && $scope.sectionList && $scope.sectionList.length && $scope.propedeuticReferenceId) {
				$scope.isItemDisabled = true;
				let mandatoryItems = 0;
				let consumedMandatoryItems = 0;
				// Scorro tutti gli id propedeutici (anche se mi aspetto ce ne sia solo uno)
				for (let i = 0; i < $scope.propedeuticReferenceIds.length; i++) {
					// Scorro tutte altre sezioni del progetto in cerca dell'id
					for (let k = 0; k < $scope.sectionList.length; k++) {
						if ($scope.sectionList[k].item.itemId == $scope.propedeuticReferenceIds[i]) {
							let myPropeduticSection: Item = $scope.sectionList[k].item;
							// Verifico se la sezione è stata marcata come consumata
							for (let f = 0; myPropeduticSection.engagements && f < myPropeduticSection.engagements.length; f++) {
								if (myPropeduticSection.engagements[f].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
									$scope.isItemDisabled = false;
									break;
								}
							}
						}
					}
				}
			}

			// Recupero le info degli itemChilds per i contatori degli stati
			// Oggetti della sezione
			if ($scope.item.itemChilds) {
				for (let z = 0; z < $scope.item.itemChilds.length; z++) {
					let blocked = false;
					let cert = false;
					let viewed = false;
					for (let k = 0; $scope.item.itemChilds[z].childObject && $scope.item.itemChilds[z].childObject.engagements && k < $scope.item.itemChilds[z].childObject.engagements.length; k++) {
						if ($scope.item.itemChilds[z].childObject.engagements[k].moduleName == LibraryApplicationData.constants.ITEM
							&& $scope.item.itemChilds[z].childObject.engagements[k].eventName == LibraryApplicationData.constants.EVENT_ITEM_CERTIFIED) {
							// Oggetto certificato
							cert = true;
						} else if ($scope.item.itemChilds[z].childObject.engagements[k].moduleName == LibraryApplicationData.constants.ITEM
							&& $scope.item.itemChilds[z].childObject.engagements[k].eventName == LibraryApplicationData.constants.EVENT_ITEM_OPEN) {
							// Oggetto visto
							viewed = true;
						}
					}

					// Se ha un propedeutico, verifico se è stato fatto o meno
					if ($scope.item.itemChilds[z].propedeuticReferenceId) {
						let isEnabled = false;
						for (let p = 0; p < $scope.item.itemChilds.length && !isEnabled; p++) {
							if ($scope.item.itemChilds[p].childObject && $scope.item.itemChilds[p].referenceId == $scope.item.itemChilds[z].propedeuticReferenceId) {
								for (let j = 0; j < $scope.item.itemChilds[p].childObject.engagements.length; j++) {
									if ($scope.item.itemChilds[p].childObject.engagements[j].moduleName == LibraryApplicationData.constants.ITEM
										&& $scope.item.itemChilds[p].childObject.engagements[j].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
										// Se l'oggetto associato è stato consumato, l'oggetto con il propedeuticReferenceId è sbloccato
										isEnabled = true;
										break;
									}
								}
							}
						}
						if (!isEnabled) {
							// Oggetto bloccato
							blocked = true;
						}
					}

					if (blocked) {
						$scope.totalDisabledObjInSection++;
					} else if (cert) {
						$scope.totalCertifiedObjInSection++;
					} else if (viewed) {
						$scope.totalViewedObjInSection++;
					} else {
						// Pallino vuoto, con nessuna azione ancora effettutata
						$scope.totalWithAnyActionObjInSection++;
					}
				}
			}

			// Se possiede la scormRegistration, prendo da qui la percentuale di avanzamento
			let hasScormRegistration: boolean = $scope.fullItem && $scope.fullItem.scormRegistration ? true : false;
			if (hasScormRegistration) {
				$scope.percentageItem = $scope.fullItem.scormRegistration.score;
			}

			// Se possiede l'itemRegistration (quindi non è uno scorm ma è un oggetto Kaltura), prendo da qui la percentuale di avanzamento
			let hasItemRegistration: boolean = $scope.item && $scope.item.itemRegistration ? true : false;
			if (hasItemRegistration) {
				$scope.percentageItem = $scope.item.itemRegistration.currentPrecentage;
			}

			// Verifico gli engagement della sezione, cioè le azioni dell'utente sull'Item corrente
			for (let d = 0; d < $scope.item.engagements.length; d++) {
				let found = false;
				if ($scope.item.engagements[d].moduleName == LibraryApplicationData.constants.ITEM && (
					$scope.item.engagements[d].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED ||
					$scope.item.engagements[d].eventName == LibraryApplicationData.constants.EVENT_ITEM_STARTED)) {
					// Recupero la percentuale
					if (!hasItemRegistration && !hasScormRegistration && $scope.item.engagements[d].engagementDetails) {
						for (let z = 0; z < $scope.item.engagements[d].engagementDetails.length; z++) {
							if ($scope.item.engagements[d].engagementDetails[z].detailKey == 'PERCENTAGE') {
								$scope.percentageItem = $scope.item.engagements[d].engagementDetails[z].detailValue;
								found = true;
								break;
							}
						}
					}
				}
				else if ($scope.item.engagements[d].moduleName == LibraryApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == LibraryApplicationData.constants.EVENT_ITEM_OPEN) {
					// Oggetto aperto
					$scope.isItemOpen = true;
				}
				if (found) {
					break;
				}

				// Verifico la certificazione
				if ($scope.item.engagements[d].moduleName == LibraryApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == LibraryApplicationData.constants.EVENT_ITEM_CERTIFIED) {
					// L'oggetto è già stato certificato
					$scope.isItemAlreadyCertified = true;
				} else {
					// Verifico se esiste una survey associata a questo oggetto
					SurveyService.getSurveys.query({
						referenceId: $scope.item.itemId
					}).$promise
						.then((data: SenecaResponse<Survey>) => {
							if (data.error) {
								// Dati non validi, quindi alzo l'errore
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								if (data.response && data.response.surveyStatus) {
									if (data.response.surveyStatus == 'STARTED') {
										// Survey iniziata
										$scope.isSurveyStarted = true;
									} else if (data.response.surveyStatus == 'VALID') {
										// Se lo status è valido, significa che ho una survey certificabile
										$scope.isSurveyCertificable = true;
									}
								}
							}
						})
						.catch((error: any) => {
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}
			}
		}

		$scope.windowWidth = $window.innerWidth;
		// Cambio la classe del titolo in base alla sua lunghezza, così diminuendo il font-size evito che sfori
		if ($scope.item && $scope.item && $scope.item.title && $scope.item.title.length < 62) {
			$scope.item.titleClass = 'card-title-h3';
		} else if ($scope.item && $scope.item && $scope.item.title && $scope.item.title.length > 62) {
			$scope.item.titleClass = 'card-title-h2';
		}

		// Eseguo la verifica sul carattere anche al resize e al caricamento della pagina
		angular.element($window).bind('resize', () => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if ($scope.windowWidth < 680 && $scope.item && $scope.item && $scope.item.title && $scope.item.title.length < 62) {
				$scope.item.titleClass = 'card-title-h6';
			} else if ($scope.isWidthExceeded == true) {
				$scope.item.titleClass = 'card-title-h3';
			}

			if ($scope.windowWidth < 680 && $scope.item && $scope.item && $scope.item.title && $scope.item.title.length > 62) {
				$scope.item.titleClass = 'card-title-h5';
			} else if ($scope.isWidthExceeded == true) {
				$scope.item.titleClass = 'card-title-h2';
			}
		});
		// e devo controllare al caricamento della pagina
		angular.element(document).ready(() => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if ($scope.windowWidth < 680 && $scope.item && $scope.item && $scope.item.title && $scope.item.title.length < 62) {
				$scope.item.titleClass = 'card-title-h6';
			} else if ($scope.isWidthExceeded == true) {
				$scope.item.titleClass = 'card-title-h3';
			}

			if ($scope.windowWidth < 680 && $scope.item && $scope.item && $scope.item.title && $scope.item.title.length > 62) {
				$scope.item.titleClass = 'card-title-h5';
			} else if ($scope.isWidthExceeded == true) {
				$scope.item.titleClass = 'card-title-h2';
			}

			if(!$scope.isItemDisabled) {
				let elementDiv = document.getElementById('section-div-' + ($scope.item && $scope.item.itemId));
				if(elementDiv) {
					elementDiv.removeAttribute('role');
				}
			}
		});

		// Verifico gli attributi dell'oggetto
		if ($scope.item) {
			if ($scope.item && $scope.item.itemAttributes) {
				let itemAttributes = $scope.item.itemAttributes;
				for (let k = 0; k < itemAttributes.length; k++) {
					// Verifico se è una competenza soft
					if (itemAttributes[k].attributeType == LibraryApplicationData.constants.SOFT_SKILL) {
						$scope.isSoftSkill = true;
					}

					// Verifico se è una competenza tecnica
					if (itemAttributes[k].attributeType == LibraryApplicationData.constants.TECHNICAL_SKILL) {
						$scope.isTechSkill = true;
					}

					// Verifico il tipo di oggetto
					if (itemAttributes[k].attributeType == LibraryApplicationData.constants.OBJECT_TYPE) {
						$scope.itemType = itemAttributes[k].attributeValue;
						if ($scope.itemType == LibraryApplicationData.constants.LEARNING_PLAN) {
							$scope.isLearningPlan = true;
						}
					}

					// Verifico se c'è il valore formativo
					if (itemAttributes[k].attributeType == LibraryApplicationData.constants.VALUE) {
						$scope.hourValue = itemAttributes[k].attributeValue;
					}

					// Recupero i tag - se non c'è il crossReferenceObject significa che non è un vero tag, quindi lo salto
					if (itemAttributes[k].crossReferenceObject) {
						if (itemAttributes[k].attributeType == LibraryApplicationData.constants.TECH_COMPETENCES) {
							$scope.techCompetences.push(itemAttributes[k].crossReferenceObject.title);
						} else if (itemAttributes[k].attributeType == LibraryApplicationData.constants.SOFT_COMPETENCES) {
							$scope.softCompetences.push(itemAttributes[k].crossReferenceObject.title);
						}
					}
				}
			}

			// Se possiede la scormRegistration, prendo da qui la percentuale di avanzamento
			let hasScormRegistration: boolean = $scope.fullItem && $scope.fullItem.scormRegistration ? true : false;
			if (hasScormRegistration) {
				$scope.percentageItem = $scope.fullItem.scormRegistration.score;
			}
			// Se possiede l'itemRegistration (quindi non è uno scorm ma è un oggetto Kaltura), prendo da qui la percentuale di avanzamento
			let hasItemRegistration: boolean = $scope.item && $scope.item.itemRegistration ? true : false;
			if (hasItemRegistration) {
				$scope.percentageItem = $scope.item.itemRegistration.currentPrecentage;
			}

			// Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
			if ($scope.item && $scope.item.engagements) {
				for (let d = 0; d < $scope.item.engagements.length; d++) {
					let found = false;

					if ($scope.item.engagements[d].moduleName == LibraryApplicationData.constants.ITEM && (
						$scope.item.engagements[d].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED ||
						$scope.item.engagements[d].eventName == LibraryApplicationData.constants.EVENT_ITEM_STARTED)) {
						// Recupero la percentuale
						if (!hasItemRegistration && !hasScormRegistration && $scope.item.engagements[d].engagementDetails) {
							for (let z = 0; z < $scope.item.engagements[d].engagementDetails.length; z++) {
								if ($scope.item.engagements[d].engagementDetails[z].detailKey == 'PERCENTAGE') {
									$scope.percentageItem = $scope.item.engagements[d].engagementDetails[z].detailValue;
									found = true;
									break;
								}
							}
						}
					}
					else if ($scope.item.engagements[d].moduleName == LibraryApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == LibraryApplicationData.constants.EVENT_ITEM_OPEN) {
						// Oggetto aperto
						$scope.isItemOpen = true;
					}
					if (found) {
						break;
					}

					// Verifico la certificazione
					if ($scope.item.engagements[d].moduleName == LibraryApplicationData.constants.SURVEY) {
						// L'oggetto è già stato certificato
						$scope.isItemAlreadyCertified = true;
					}
				}
			}

			// TODO-Allloy cerco anche fra i dati che ora mi mancano (certificazione, etc)
			if ($scope.isItemOpen || $scope.percentageItem || $scope.isItemAlreadyCertified) {
				$scope.defaultOnSuggestion = false;
			}

			if ($scope.item && $scope.item.itemVisibilities) {
				// Ordino le visibilities per data
				$scope.item.itemVisibilities.sort((a: any, b: any) => {
					if (a.creationDate > b.creationDate) {
						return -1;
					} else if (a.creationDate < b.creationDate) {
						return 1;
					} else {
						return 0;
					}
				});

				// Verifico se ci sono suggeriti (visibility = 'suggestion'), e se ci sono li aggiungo alla lista
				for (let j = 0; j < $scope.item.itemVisibilities.length; j++) {
					// Se è stato suggerito dall'amministrazione, ha la precedenza
					if ($scope.item.itemVisibilities[j].visibilityType == LibraryApplicationData.constants.SUGGESTION && $scope.item.itemVisibilities[j].fromAdmin == true) {
						$scope.suggestedFromAdmin = true;
						$scope.hasSuggested = true;
						break;
					} else if ($scope.item.itemVisibilities[j].visibilityType == LibraryApplicationData.constants.SUGGESTION) {
						// Verifico se ho i permessi per visualizzarlo
						let groupFound: boolean = false;
						for (let z = 0; z < $scope.globalApplicationData.userGroups.length; z++) {
							if ($scope.globalApplicationData.userGroups[z].groupId == $scope.item.itemVisibilities[j].allowedGroupId) {
								groupFound = true;
								break;
							}
						}
						if (groupFound || ($scope.globalApplicationData.jwtPayload.user.userId == $scope.item.itemVisibilities[j].allowedUserId)) {
							$scope.hasSuggested = true;

							// Salvo chi me l'ha suggerito
							$scope.suggestedPerson = $scope.item.itemVisibilities[j].userObject;
							break;
						}
					}
				}
			}
		}

		// Controllo quale icona mostrare
		$scope.iconToShow = "EMPTY";
		$scope.iconToShowAlt = "EMPTY";
		// Versione standard
		if ($scope.isItemDisabled && $scope.page != 'editLearningPlanAdmin' && $scope.page != 'editSection' && $scope.page != 'editProject') {
			// Disabilito l'item corrente se ce n'è un'altro obbligatorio
			$scope.iconToShow = "DISABLED";
		}
		if ($scope.percentageItem) {
			$scope.iconToShow = "PERCENTAGE";
		}
		else if ($scope.isItemOpen) {
			$scope.iconToShow = "OPEN";
		}
		else if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}
		// Versione con forza persona
		if ($scope.isItemDisabled && $scope.page != 'editLearningPlanAdmin' && $scope.page != 'editSection' && $scope.page != 'editProject') {
			// Disabilito l'item corrente se ce n'è un'altro obbligatorio
			$scope.iconToShowAlt = "DISABLED";
		}
		else if ($scope.forceToggleIndicator && $scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.forceToggleIndicator && $scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}
		else if ($scope.percentageItem) {
			if ($scope.suggestedFromAdmin) {
				$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested) {
				$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "PERCENTAGE";
			}
		}
		else if ($scope.isItemOpen) {
			if ($scope.suggestedFromAdmin) {
				$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested) {
				$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "OPEN";
			}
		}
		else if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}

		// Porta alla pagina di dettaglio (o editing) della sezione
		$scope.openSection = () => {
			if ($scope.isEditing == "true") {
				$state.go("app.libraryApp.editSection", { projectId: $stateParams.itemId, sectionId: $scope.item.itemId });
			} else {
				// Apro la sezione, salvando il progetto da cui sto provenendo
				$state.go("app.libraryApp.sectionDetail", { projectId: $stateParams.itemId, itemId: $scope.item.itemId });
			}
		}
	}
});